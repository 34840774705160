// @flow
import {Controller} from '@hotwired/stimulus';

import type {ValueTypes} from './types';

export type ControllerValues = {
  isLoading: ValueTypes,
};

/**
 * Handles fetching new Pagy pages asynchronously and replacing this controller.
 */
export default class extends Controller {
  static values: ControllerValues = {
    isLoading: Boolean,
  };

  getPage(event: Event): boolean {
    event.preventDefault();
    const target = (event.currentTarget: any);
    if (!(event.currentTarget instanceof HTMLElement)) {
      return false;
    }
    target.classList.add('is-loading');
    this.isLoadingValue = true;
    $.get(
      target.getAttribute('href') ?? '',
      (data: any, _textStatus: string, _jqXHR: JQueryXHR) => {
        // Create a fake node so we can parse the HTML.
        const htmlNode = document.createElement('html');
        htmlNode.innerHTML = data;

        /**
         * There may be multiple controllers defined.
         * Find the Pagy controller and replace the content of this node.
         */
        const controllerNodes = htmlNode.querySelectorAll('[data-controller]');
        const pagyController = [...controllerNodes].find(node =>
          node.dataset.controller.includes('pagy'),
        );
        const nextHTML = pagyController?.innerHTML;

        if (nextHTML != null) {
          this.element.innerHTML = nextHTML;
        }
        this.isLoadingValue = false;
        target.classList.add('is-loading');
      },
      'html',
    );
    return false;
  }
}
