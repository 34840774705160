// @flow

import type {ValueTypes} from './types';

import {Controller} from 'stimulus';
import $S from 'scriptjs';

type ControllerValues = {
  options: ValueTypes,
};

export default class HcaptchaController extends Controller {
  static values: ControllerValues = {
    options: Object,
  };

  connect() {
    $S('https://js.hcaptcha.com/1/api.js?render=explicit', () => {
      window.hcaptcha && window.hcaptcha.render(this.element, this.options);

      window.hcaptchaControllerOnSuccess = () => {
        this.dispatch('success', {
          detail: {
            element: this.element,
          },
        });
      };
    });
  }
}
