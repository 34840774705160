import {Controller} from '@hotwired/stimulus';

import type {ValueTypes} from './types';

export type ControllerValues = {
  type: ValueTypes,
};

/**
 * Builds a file from a raw input stream and allows the user to
 * download it. Add your data to the `downloadContent` target
 * and wire up your button to the download function.
 */
export default class extends Controller {
  static targets: Array<string> = ['downloadContent'];

  download(event): void {
    const downloadContent = this.downloadContentTarget.value;

    const blob = new Blob([downloadContent], {
      type: event.params.filetype || 'text/csv',
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', event.params.filename || 'download.csv');
    a.click();
  }
}
