// @flow

import {Controller} from '@hotwired/stimulus';
import type {ValueTypes} from './types';

export type ControllerValues = {
  url: ValueTypes,
};

export default class extends Controller {
  static values: ControllerValues = {
    url: String,
  };

  connect(): void {
    $.get(this.remoteUrlValue, (data: string) => {
      this.element.outerHTML = data;
    });
  }
}
