// @flow
import {Controller} from '@hotwired/stimulus';
import type {ValueTypes} from './types';

export type ControllerValues = {
  modalId: ValueTypes,
  modalHeader: ValueTypes,
  modalUrl: ValueTypes,
  /**
   * If specified we will push given URL to History API.
   * When the modal is closed, we'll pop the stack.
   */
  pushUrl: ValueTypes,
};

export default class extends Controller {
  static values: ControllerValues = {
    modalId: String,
    modalHeader: String,
    modalUrl: String,
    pushUrl: String,
  };

  launch(_event: Event): void {
    /* develblock:start */
    console.debug('Launching modal... %s', this.modalIdValue);
    /* develblock:end */
    this.dispatch('launch', {
      detail: {
        modalId: this.modalIdValue,
        modalHeader: this.modalHeaderValue,
        modalUrl: this.modalUrlValue,
        pushUrl: this.pushUrlValue,
      },
    });
  }
}
