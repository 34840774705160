// @flow

import {Controller} from '@hotwired/stimulus';
// eslint-disable-next-line no-unused-vars
import Swiper, {Navigation, Pagination} from 'swiper';
import {triggerError, triggerClear} from './form_input_controller';

import type {StimulusValueTypes, EventFunction} from './types';

Swiper.use([Navigation, Pagination]);

/**
 * An h-scroll based survey with basic form input validation.
 *
 */
export default class extends Controller {
  static values: StimulusValueTypes = {
    swiperOptions: Object,
    surface: String,
  };

  static targets: Array<string> = ['nextButton', 'swiper', 'steps'];

  connect(): void {
    // We need a tiny delay here because otherwise the heights don't format
    // for the images and it's stuck.
    requestAnimationFrame(() => {
      this.swiper = new Swiper(this.swiperTarget, {
        ...this.defaultOptions,
        ...this.swiperOptionsValue,
      });

      this.swiper.on('slideChange', swiper => {
        const indexPlus1 = swiper.activeIndex + 1;
        if (this.hasStepsTarget) {
          const activeNode = this.stepsTarget.querySelector(
            `.steps .steps-segment:nth-child(${indexPlus1})`,
          );
          this.stepsTarget
            .querySelectorAll(`.steps .steps-segment.is-active`)
            .forEach(node => {
              node.classList.remove('is-active');
            });
          activeNode && activeNode.classList.add('is-active');
        }
      });
    });
  }

  resize: () => void = () => {
    this.swiper && this.swiper.updateAutoHeight();
  };

  handlePrevBtnClick: EventFunction = _ => {
    this.swiper.slidePrev();
  };

  handleNextBtnClick: EventFunction = event => {
    const inputParam = event?.params?.input;
    const inputSelectorParam = event.params?.inputSelector;
    if (inputParam || inputSelectorParam) {
      // Validates that an input is present.
      const inputNode = inputSelectorParam
        ? this.element.querySelector(inputSelectorParam)
        : this.element.querySelector(`input[name='${inputParam}']`);
      if (!inputNode) {
        console.warn(
          'Expected node with selector (%s) or name (%s)',
          inputSelectorParam,
          inputParam,
        );
        return;
      }
      const currentValue = inputNode.value.trim();
      const validationNode = this.element.querySelector(
        event.params?.inputValidationErrorSelector,
      );
      if (currentValue) {
        triggerClear(inputNode, {
          detail: {
            messageNode: validationNode,
          },
        });
      } else {
        triggerError(inputNode, {
          detail: {
            message: 'This field is required.',
            messageNode: validationNode,
          },
        });
        return;
      }
    }
    this.swiper?.slideNext();
  };

  disconnect(): void {
    this.swiper.destroy();
    this.swiper = undefined;
  }

  get defaultOptions(): Object {
    return {
      allowTouchMove: false,
      speed: 800,
      slidesPerView: 1,
    };
  }
}
