// @flow
import {Controller} from '@hotwired/stimulus';
import type {StimulusEvent, StimulusValueTypes} from './types';

export default class extends Controller {
  static values: StimulusValueTypes = {
    text: String,
  };
  copy(_: StimulusEvent): void {
    const textValue = this.textValue;
    if (textValue) {
      const textarea = document.createElement('textarea');
      document.body?.appendChild(textarea);
      textarea.value = textValue;
      textarea.select();
      document.execCommand('copy');
      textarea.remove();

      sweetAlert.fire({
        type: 'success',
        toast: true,
        text: 'Copied',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: true,
        timer: 2000,
        timerProgressBar: true,
        position: 'top-end',
      });
    } else {
      console.error('No text to copy!');
    }
  }
}
