// @flow

import type {StimulusEvent, ValueTypes} from './types';

import {Controller} from 'stimulus';

type ControllerValues = {
  options: ValueTypes,
  fromId: ValueTypes,
};

export default class EcommPreviewController extends Controller {
  static values: ControllerValues = {};
  static targets: Array<string> = [
    'textInput',
    'uploadInput',
    'uploadButtonInput',
    'cancelButtonInput',
  ];

  connect(): void {}

  handleFileCanceled(): void {
    this.uploadInputTarget.value = null;
    this.resetUI();
  }

  resetUI(): void {
    this.textInputTarget.removeAttribute('readonly');
    this.textInputTarget.removeAttribute('disabled');
    this.textInputTarget.value = '';
    this.uploadButtonInputTarget.classList.remove('is-hidden');
    this.uploadInputTarget.classList.remove('is-hidden');
    this.cancelButtonInputTarget.classList.add('is-hidden');
  }

  handleFileChanged(event: StimulusEvent): void {
    const file = (event.target: any).files[0];
    if (file) {
      this.textInputTarget.value = file.name;
      this.textInputTarget.setAttribute('readonly', true);
      this.textInputTarget.setAttribute('disabled', true);
      this.cancelButtonInputTarget.classList.remove('is-hidden');
      this.uploadButtonInputTarget.classList.add('is-hidden');
      // Hide upload button so you can cancel now.
      this.uploadInputTarget.classList.add('is-hidden');
    } else {
      this.resetUI();
    }
  }
}
