import {Controller} from '@hotwired/stimulus';

/**
 * If you don't want complicated logic, this can replace a node with another node.
 * This can be used to have an image that is loading to swap with another thing.
 *
 * Listen to the event with event->toggle#swap
 * This will hide the node with data-toggle-target="before" and
 * make data-toggle-target="after" appear.
 */
export default class extends Controller {
  connect(): void {
    this.build();
  }

  disconnect(): void {}

  build(): void {
    if (this.isGenerated) {
      return;
    }
    document.querySelectorAll('h2, h3, h4, h5, h6').forEach(headerNode => {
      let element = document.createElement('a');
      element.classList.add('is-block');
      element.setAttribute('href', '#' + headerNode.getAttribute('id'));
      element.dataset.turbolinks = 'false';
      let leftPadding = 0;
      if (headerNode.tagName === 'H3') {
        leftPadding = 2;
      } else if (headerNode.tagName === 'H4') {
        leftPadding = 4;
      } else if (headerNode.tagName === 'H5') {
        leftPadding = 6;
      } else if (headerNode.tagName === 'H6') {
        leftPadding = 8;
      }
      element.innerHTML = '&nbsp;'.repeat(leftPadding) + headerNode.innerText;
      this.element.appendChild(element);
    });
    this.isGenerated = true;
  }

  declare isGenerated: boolean;
  declare listener: any;
}
