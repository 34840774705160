// @flow

import {Controller} from '@hotwired/stimulus';
import type {StimulusEvent} from './types';

/**
 * Allows you to log event on an input (such as submissions)
 * You can specify data-ga-target="input" and the form input will be sent with the event.
 * You can also use this to log outbound clicks to Google.
 *
 * Example data attributes:
 * ga_event_label_param: "Ecomm List Preview - Download Sample"
 * ga_event_action_param: "click"
 * action: "ga#fire"
 *
 */
export default class extends Controller {
  static targets: Array<string> = ['input'];

  fireInput(event: StimulusEvent): void {
    const {eventAction, eventCategory, eventLabel, eventValue} = event.params;
    if (!eventAction) {
      return;
    }
    this._sendGAEvent(eventAction, eventLabel, eventCategory, eventValue);
  }

  fire(event: StimulusEvent): void {
    const {eventAction, eventCategory, eventLabel, eventValue} = event.params;
    if (!eventAction) {
      return;
    }
    this._sendGAEvent(eventAction, eventLabel, eventCategory, eventValue);
  }

  _sendGAEvent(
    eventAction: string,
    eventLabel: ?string,
    eventCategory: ?string,
    eventValue: ?string,
  ): void {
    const extraData = {};
    if (eventLabel != null) {
      extraData['event_label'] = eventLabel;
    }
    if (eventCategory != null) {
      extraData['event_category'] = eventCategory;
    }
    if (eventLabel != null) {
      extraData['value'] = eventValue;
    }
    if (this.hasInputTarget && this.inputTarget) {
      const inputValue = this.inputTarget.value;
      extraData['query'] = inputValue;
    }

    window.gtag && window.gtag('event', eventAction, extraData);
  }
}
