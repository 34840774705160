// @flow
import {Controller} from '@hotwired/stimulus';

import type {ValueTypes} from './types';

export type ControllerValues = {
  url: ValueTypes,
  interval: ValueTypes,
  isLoading: ValueTypes,
  isEnabled: ValueTypes,
};

const DEFAULT_INTERVAL_VALUE = 5000;

/**
 * Polls an endpoint and replaces the HTML content of `container`
 * See example below:
div [
  data-controller="polling"
  data-polling-url-value=status_email_verification_url(id: params[:id])
  data-polling-interval-value=8000
]
  div data-polling-target="container"
OR  - In this variant, you only need 1 view.
div [
  data-controller="polling"
  data-polling-url-value=status_email_verification_url(id: params[:id])
  data-polling-interval-value=8000
]
  <p>Your Content Here</p>
 */
export default class extends Controller {
  static targets: Array<string> = ['container'];
  static classes: Array<string> = ['loading'];
  static values: ControllerValues = {
    url: String,
    interval: Number,
    isLoading: Boolean,
    isEnabled: {
      type: Boolean,
      default: true,
    },
  };

  connect(): void {
    if (!this.urlValue) {
      console.error('No URL value was found.');
    }
    this.interval = setInterval(() => {
      this.poll();
    }, this.intervalValue || DEFAULT_INTERVAL_VALUE);
    this.poll();
  }

  disconnect(): void {
    this.interval && clearInterval(this.interval);
  }

  isLoadingValueChanged() {
    const nodeWithLoadingIndicator = this.hasContainerTarget
      ? this.containerTarget
      : this.element;

    if (!this.hasLoadingClass) {
      return;
    }

    if (this.isLoadingValue) {
      nodeWithLoadingIndicator.classList.remove(this.loadingClass);
      nodeWithLoadingIndicator.classList.add(this.loadingClass);
    } else {
      nodeWithLoadingIndicator.classList.add(this.loadingClass);
      nodeWithLoadingIndicator.classList.remove(this.loadingClass);
    }
  }

  poll(): void {
    this.isLoadingValue = true;
    if (!this.urlValue || !this.isEnabledValue) {
      return;
    }
    $.get(
      this.urlValue,
      (data: any, _textStatus: string, _jqXHR: JQueryXHR) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, 'text/html');
        if (this.hasContainerTarget) {
          this.containerTarget.innerHTML = data;
          const canPollInput = doc.querySelector(
            '[name="polling_controller_can_poll"]',
          );
          if (canPollInput instanceof HTMLInputElement) {
            this.isEnabledValue = canPollInput.value === '1' ? true : false;
          }
        } else {
          const pollingContainer = doc.querySelector(
            '[data-controller*="polling"]',
          );
          const html = pollingContainer?.innerHTML;
          if (!html) {
            console.warn(
              'Polling controller fetched no HTML. Destroying parent.',
            );
            this.disconnect();
            this.element.outerHTML = '';
          }
          this.element.innerHTML = html;
        }
        this.isLoadingValue = false;
      },
      'html',
    );
  }
}
