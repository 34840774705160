// @flow
import {Controller} from '@hotwired/stimulus';

/**
 * Use this controller if you'd like to reveal component in a component.
 * Add it to the parent component.
 * <div data-controller="reveal">
 *  <div class="is-hidden" data-reveal-target="revealable"></div>
 *  <button data-action="reveal->show"></button>
 * </div>
 * Supports animation via anim.xyz
 */
export default class extends Controller {
  static targets: Array<string> = ['revealable'];

  hide(): void {
    this.revealableTargets.forEach(node => {
      node.classList.add('is-hidden');
    });
  }

  toggle(): void {
    this.revealableTargets.forEach(node => {
      if (node.classList.contains('is-hidden')) {
        node.classList.remove('is-hidden');
      } else {
        node.classList.add('is-hidden');
      }
    });
  }

  show(): void {
    this.revealableTargets.forEach(node => {
      node.classList.remove('is-hidden');
    });
  }
}
