import '@cable_ready/polyfills';
import 'regenerator-runtime/runtime';

// Stimulus
import {Application} from '@hotwired/stimulus';
import ReadMoreController from 'stimulus-read-more';
import CarouselController from './controllers/carousel_controller';
import ChartController from './controllers/chart_controller.js';
import ClickableTableController from './controllers/clickable_table_controller';
import ClipboardController from './controllers/clipboard_controller';
import DownloadController from './controllers/download_controller.js';
import DropdownController from './controllers/dropdown_controller';
import EcommPreviewerController from './controllers/ecomm_previewer_controller';
import ExpandableSectionController from './controllers/expandable_section_controller';
import FacebookPixelController from './controllers/facebook_pixel_controller';
import FormInputController from './controllers/form_input_controller';
import GoogleAnalyticsController from './controllers/google_analytics_controller';
import GridController from './controllers/grid_controller';
import HcaptchaController from './controllers/hcaptcha_controller';
import Modal from './controllers/modal_controller';
import ModalLauncher from './controllers/modal_launcher_controller';
import NavbarController from './controllers/navbar_controller.js';
import PagyController from './controllers/pagy_controller';
import PollingController from './controllers/polling_controller';
import RemoteContentController from './controllers/remote_content_controller';
import RemoteController from './controllers/remote_controller';
import RevealController from './controllers/reveal_controller';
import SlimSelectController from './controllers/slim_select_controller';
import SurveyController from './controllers/survey_controller';
import TableOfContentsController from './controllers/table_of_contents_controller';
import TabsController from './controllers/tabs_controller';
import ToggleController from './controllers/toggle_controller';

const application = Application.start();
// Start Stimulus-Components
application.register('carousel', CarouselController);
application.register('chart', ChartController);
application.register('clickable-table', ClickableTableController);
application.register('clipboard', ClipboardController);
application.register('dropdown', DropdownController);
application.register('expandable-section', ExpandableSectionController);
application.register('facebook-pixel', FacebookPixelController);
application.register('form-input', FormInputController);
application.register('ga', GoogleAnalyticsController);
application.register('grid', GridController);
application.register('hcaptcha', HcaptchaController);
application.register('modal-launcher', ModalLauncher);
application.register('modal', Modal);
application.register('navbar', NavbarController);
application.register('pagy', PagyController);
application.register('polling', PollingController);
application.register('read-more', ReadMoreController);
application.register('remote-content', RemoteContentController);
application.register('remote', RemoteController);
application.register('reveal', RevealController);
application.register('slimselect', SlimSelectController);
application.register('survey', SurveyController);
application.register('tabs', TabsController);
application.register('toggle', ToggleController);
application.register('ecomm-api-previewer', EcommPreviewerController);
application.register('download', DownloadController);
application.register('table-of-contents', TableOfContentsController);

window.Stimulus = application;
