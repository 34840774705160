// @flow

import {Controller} from '@hotwired/stimulus';
import type {StimulusEvent} from './types';

export default class extends Controller {
  fire(event: StimulusEvent): void {
    const arg0 = event.params.arg0;
    const fbEvent = event.params.event;
    if (arg0 && fbEvent) {
      window.fbq && window.fbq(fbEvent, arg0);
    }
  }
}
