// @flow

import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets: Array<string> = ['burger', 'menu'];

  toggle(): void {
    this.menuTarget.classList.toggle('is-active');
    this.burgerTarget.classList.toggle('is-active');
  }
}
