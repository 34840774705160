// @flow

import type {ValueTypes} from './types';

import {Controller} from 'stimulus';
import SlimSelect from 'slim-select';
import debounce from 'debounce';

type ControllerValues = {
  remoteUrl: ValueTypes,
  options: ValueTypes,
};

export default class SlimSelectController extends Controller {
  static values: ControllerValues = {
    remoteUrl: String,
    options: {
      type: Object,
      default: {},
    },
  };

  connect() {
    const debouncedFetch = debounce((query, callback) => {
      $.get(this.remoteUrlValue, response => {
        if (response['data']) {
          callback(response['data']);
        } else {
          callback('Error searching');
        }
      });
    }, 250);

    const remoteOptions = this.remoteUrlValue
      ? {
          ajax: (search, callback) => {
            if (search.length < 3) {
              callback('Add at least 3 characters...');
              return;
            }
            debouncedFetch(search, callback);
          },
        }
      : {};

    this.slimselect = new SlimSelect({
      select: this.element,
      searchingText: 'Searching...',
      ...remoteOptions,
      ...this.optionsValue,
    });
  }

  disconnect(): void {
    this.slimselect && this.slimselect.destroy();
  }
}
