import {Controller} from '@hotwired/stimulus';

const CLICKABLE_ELEMENTS = ['A', 'BUTTON', 'INPUT'];

declare var Turbolinks: any;

interface StimulusEvent extends Event {
  params: any;
  detail: any;
}

const isClickableElement = (target: Element) => {
  return (
    CLICKABLE_ELEMENTS.includes(target?.tagName) ||
    Boolean(target?.closest('.dropdown-trigger'))
  );
};

const isInsideOfAClickableElement = (event: Event): boolean => {
  const target = event.target;
  if (!(target instanceof HTMLElement)) {
    return false;
  }

  const closestElement = target.closest('a');
  if (closestElement == null) {
    return false;
  }

  return closestElement != event.currentTarget;
};

export default class extends Controller {
  visit(event: StimulusEvent): void {
    const url = event?.params?.url;
    if (url == null) {
      return;
    }

    // Check if this click is on an element INSIDE of the row that is clickable.
    if (
      event.target instanceof Element &&
      (isClickableElement(event.target) || isInsideOfAClickableElement(event))
    ) {
      // This allows the table to have other clickable elements inside of it.
      // We only react on the TD element
      return;
    }

    if (event.type === 'auxclick') {
      window.open(url, '_blank');
      return;
    }

    Turbolinks.visit(url);
  }
}
